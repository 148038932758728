<template>
  <div class="infoStrip d-flex">
    <div class="totalresult_right flex-fill" v-if="categoryName === 'Flight_Only'">
      <h3>{{ $t("search-result.flights-only-rates") }}</h3>
      <p>{{ $t("search-result.best-flights-rates") }}</p>
    </div>
    <div class="totalresult_right flex-fill" v-else-if="categoryName === 'Organize_tour_packages'">
      <h3>{{ $t("search-result.organize-packages") }}</h3>
      <p>{{ $t("search-result.organize-packages-rates") }}</p>
      <p>{{ tripDuration }}</p>
    </div>
    <div class="totalresult_right flex-fill" v-else>
      <h3>{{ $t("search-result.leisure-packages") }}</h3>
      <p>{{ $t("search-result.best-packages-rates") }}</p>
      <p>{{ tripDuration }}</p>
    </div>
    <div class="totalresults flex-fill d-flex">
      <img v-if="loading" :src="`${imageDomain}/assets/img/spinner.gif`" alt="spinner" class="loading m-auto" width="100" height="100"/>
      <p v-else class="m-auto" style="text-align: center;">{{ $t("search-result.total") }}  <br/><span style="font-weight:700;font-size:25px;">{{ count }}</span> <br/>{{ $t("search-result.result") }} </p>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'InforStrip',
  mixins: [imageUrlMixin],
  components: {},
  props: {
    categoryName: String,
    count: Number,
    loading: Boolean,
    tripDuration: String,
  },
  data() {
    return {};
  },
  computed: {
  },
};
</script>

<style scoped>
.infoStrip {
  background-color: #0061ab;
  margin-bottom: 2px;
  display: none !important;
}
.loading{
  width: 90px;
}
@media (max-width: 479px) {
  .infoStrip {
    display: flex !important;
  }
}
</style>
